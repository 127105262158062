import React from 'react'
import { graphql, Link } from "gatsby";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";

const PortfolioDetail = ({data}) => {

    const navbarRef = React.useRef(null);
    const logoRef = React.useRef(null);
    

    React.useEffect(() => {
      var navbar = navbarRef.current;
  
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
  
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          navbar.classList.add("nav-scroll");
        } else {
          navbar.classList.remove("nav-scroll");
        }
      });
    }, [navbarRef]);


    function validateEmail(value) {
        let error;
        if (!value) {
          error = "Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          error = "Invalid email address";
        }
        return error;
      }
      const sendMessage = (ms) => new Promise((r) => setTimeout(r, ms));


  const currentWork = data?.supabaseProject;



  return (
    <DarkTheme>
      
      <Navbar nr={navbarRef} lr={logoRef} />
      
      <section className="blog-pg single section-padding">

      <div className='container'>

      <div className="row">

<div
  className="col-md-6"
  
>

<div className="mt-30">

  <h3 className="color-font mb-10">{currentWork.name}</h3>
  <p>{currentWork.subtitle}</p>
</div>
<div className="mt-30">

  <h6 className="mb-10">Description: </h6>
  <p>{currentWork.description}</p>
</div>
<div className="mt-30">

  <h6 className="mb-10">Category: </h6>
  <p>
    
    {JSON.parse(currentWork.category).join(", ")

    }
</p>
</div>
<div className="mt-30">

  <h6 className="mb-10">Tags:</h6>
  <div className="tags">
      {JSON.parse(currentWork.tags)
      .map((tl, i) => {
        return (
      <span key={i}>
        <Link to="#">{tl}</Link>
      </span>

        )

      })}
      
    </div>
</div>


  </div>
<div  className="col-md-6 mb-30 mt-30">
{<img src={currentWork.image} alt="" />}



  </div>
  <div className="col-md-12">

  {JSON.parse(currentWork.gallery)
.map((el,i) => {
return (
  <div key={i} className='mb-30 mt-30'>
  <img src={el} alt="" style={{width:'100%', maxWidth:'800px'}}/>

</div>
)
})
}
  </div>
  </div>
      </div>
      </section>
      
  <Footer />
    </DarkTheme>
  );
};
export const Head = () => {
    return (
      <>
        <title>Jami - Portfolio</title>
      </>
    )
  }
export default PortfolioDetail;

export const query = graphql`
  query ($id: String!) {
     supabaseProject(id: {eq: $id}) {
    id
    name
    subtitle
    description
    category
    image
    gallery
    tags
  }
  }
`;
